import axios from 'axios';

const api_url = 'https://admin.bottax.tech/api'


export async function loginUser(username, password) {
  try {
    const response = await axios.post(`${api_url}/login`, {
      username,
      password
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка авторизации:', error.response ? error.response.data : error.message);
    return { success: false, message: 'Ошибка авторизации' };
  }
}

export async function deleteBotMessage(tg_msg_id, chat_id) {
  try {
    const response = await axios.post(`${api_url}/deleteMessage`, {
      tg_msg_id,
      chat_id,
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(
      'Ошибка удаления сообщения:',
      error.response ? error.response.data : error.message
    );
    return { success: false, message: 'Ошибка удаления сообщения' };
  }
}


