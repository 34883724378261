import React from 'react';
import './MessageItem.css';
import { svg_del } from '../static/svgs';

const MessageItem = ({ message, isSelf, deleteBotMessage }) => {

    const handleClickDelete = () => {
        deleteBotMessage(message.tg_msg_id, message.to_id);
    };

    return (
        <div className={`message-container ${isSelf ? 'other' : 'self'}`}>
            <div className="message-bubble">

                <strong>{isSelf ? 'Вы' : message.username}:</strong>
                <div>
                    {message.content}
                </div>
                {message.tg_msg_id !== 0 &&
                    <div className='svg_del' onClick={handleClickDelete}>
                        {svg_del}
                    </div>
                }
            </div>

        </div>
    );
};

export default MessageItem;

