import React from 'react';
import './MessagesModal.css';
import MessageItem from './MessageItem';
import { deleteBotMessage } from '../login/api';

const MessagesModal = ({ show, onClose, messages, setMessages, selectedUser }) => {
    if (!show) return null;

    const handleDeleteBotMessage = async (tg_msg_id, chat_id) => {
        try {
            const res_del = await deleteBotMessage(tg_msg_id, chat_id)
            if (res_del.success) {
                setMessages((prevMessages) =>
                    prevMessages.filter(
                        (message) => message.tg_msg_id !== tg_msg_id || message.to_id !== chat_id
                    )
                );
            }
        }
        catch (e) {
            console.log('handleDeleteBotMessage', e.message)
        }

    }
    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Переписка с {selectedUser.username}</h2>
                <div className="messages">
                    {messages.length > 0 ? (
                        messages.map((message) => (
                            <MessageItem
                                key={message.id}
                                message={message}
                                isSelf={message.from_id !== selectedUser.user_tg_id}
                                deleteBotMessage={handleDeleteBotMessage}
                            />
                        ))
                    ) : (
                        <p>Нет сообщений</p>
                    )}
                </div>
                <button className="close-button" onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};

export default MessagesModal;
