import React, { useState } from 'react';
import './App.css';
import Users from './components/users/Users';
import Login from './components/login/Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {isAuthenticated ? (
        <Users logOut={() => setIsAuthenticated(false)}/>
      ) : (
        <Login onLoginSuccess={() => setIsAuthenticated(true)} />
      )}
    </div>
  );
}

export default App;

